@import url("https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap");
.hero-container {
  width: 100%;
  min-height: 100vh;
  background-color: #000000;
}
.hero-container .hero-video .background-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.hero-container .hero-video .video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.hero-container .hero-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}
.hero-container .hero-title .hero-empty-space {
  padding-top: 36px;
}
.hero-container .hero-title .hero-text {
  font-size: 12px;
  text-transform: uppercase;
}
.hero-container .hero-title .hero-icon img {
  height: 30px;
}

@media (min-width: 768px) {
  .hero-container {
    width: 100%;
    min-height: 100vh;
  }
}/*# sourceMappingURL=hero.css.map */