@import url("https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap");
.shop-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  font-family: "Exo 2", sans-serif;
}
.shop-container .shop-header {
  margin: 20vh 5vw;
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
}
.shop-container .shop-header .shop-title h1 {
  font-family: "Exo 2", sans-serif;
  color: #000000;
}
.shop-container .shop-header .shop-empty-space {
  padding-top: 20px;
}
.shop-container .shop-header .shop-desc p {
  color: #7E7E7E;
}
.shop-container .shop-textbox {
  margin: 0 5vw 15vh 5vw;
}
.shop-container .shop-textbox input {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  border: 2px solid #000000;
  border-radius: 1px;
  padding: 16px 24px;
  border-right: none;
}
.shop-container .shop-textbox button {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  background-color: #ffcc00;
  border: none;
  border-radius: 1px;
  padding: 18px 42px;
  cursor: pointer;
  background-color: #000000;
  color: #ffffff;
  border-left: none;
}
.shop-container .shop-textbox button:hover {
  font-size: 14px;
  font-weight: 600;
  color: #F5F6FC;
  background-color: transparent;
  border: 2px solid #ffcc00;
  border-radius: 1px;
  padding: 16px 40px;
  border-color: #000000;
  color: #7c7c7c;
  border-left: none;
}

@media (min-width: 768px) {
  .shop-container {
    height: 100vh;
  }
  .shop-container .shop-header {
    margin: 20vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    text-align: center;
  }
  .shop-container .shop-header .shop-title h1 {
    font-family: "Exo 2", sans-serif;
    color: #000000;
  }
  .shop-container .shop-header .shop-desc {
    width: 50%;
  }
  .shop-container .shop-header .shop-desc p {
    color: #7E7E7E;
  }
}/*# sourceMappingURL=shop.css.map */