@mixin font-styling {
    font-family: "Exo 2", sans-serif;
}

@mixin button-styling {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    background-color: #ffcc00;
    border: none;
    border-radius: 1px;
    padding: 18px 42px;
    cursor: pointer;
}

@mixin button-styling-hover {
    font-size: 14px;
    font-weight: 600;
    color: #F5F6FC;
    background-color: transparent;
    border: 2px solid #ffcc00;
    border-radius: 1px;
    padding: 16px 40px;
}

@mixin input-box-style{
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    border: 2px solid #000000;
    border-radius: 1px;
    padding: 16px 24px;
}