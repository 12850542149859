@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Spectral+SC:wght@200&family=Spectral:wght@800&family=Teko:wght@300..700&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");
.image-viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000000000000000000;
}
.image-viewer .image-viewer-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.145);
  cursor: pointer;
}
.image-viewer .image-viewer-content {
  position: relative;
  max-width: 90%;
  height: 90%;
}
.image-viewer .image-viewer-content .image-in-view {
  width: 100%;
  height: inherit;
  display: block;
  -o-object-fit: contain;
     object-fit: contain;
}
.image-viewer .image-viewer-content .prev-button,
.image-viewer .image-viewer-content .next-button,
.image-viewer .image-viewer-content .close-button {
  position: absolute;
  background: none;
  border: none;
  color: white;
  height: 2.5rem;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.image-viewer .image-viewer-content .prev-button {
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.image-viewer .image-viewer-content .next-button {
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.image-viewer .image-viewer-content .close-button {
  top: 10px;
  right: 10px;
}/*# sourceMappingURL=imageViewer.css.map */