@import url("https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap");
.contact-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: auto;
}
.contact-container .contact-header {
  margin: 20vh 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}
.contact-container .contact-header .contact-title h1 {
  font-family: "Exo 2", sans-serif;
  color: #000000;
}
.contact-container .contact-header .contact-empty-space {
  padding-top: 20px;
}
.contact-container .contact-header .contact-desc p {
  color: #7E7E7E;
}
.contact-container .contact-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: left;
  padding: 0 32px 32px 32px;
}
.contact-container .contact-form form .contact-empty-space {
  padding-top: 20px;
}
.contact-container .contact-form form .form-input,
.contact-container .contact-form form .form-select,
.contact-container .contact-form form .form-textarea {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  border: 2px solid #000000;
  border-radius: 1px;
  padding: 16px 24px;
}
.contact-container .contact-form form .form-submit {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  background-color: #ffcc00;
  border: none;
  border-radius: 1px;
  padding: 18px 42px;
  cursor: pointer;
  background-color: #000000;
  color: #ffffff;
}
.contact-container .contact-form form .form-submit:hover {
  font-size: 14px;
  font-weight: 600;
  color: #F5F6FC;
  background-color: transparent;
  border: 2px solid #ffcc00;
  border-radius: 1px;
  padding: 16px 40px;
  border-color: #000000;
  color: #7c7c7c;
}

@media (min-width: 768px) {
  .contact-container {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  .contact-container .contact-header {
    margin: 20vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    text-align: center;
  }
  .contact-container .contact-form {
    padding-top: 21vh;
  }
  .contact-container .contact-form form {
    text-align: left;
    padding: 0 0;
  }
  .contact-container .contact-form form .form-input,
  .contact-container .contact-form form .form-select,
  .contact-container .contact-form form .form-textarea {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    border: 2px solid #000000;
    border-radius: 1px;
    padding: 16px 24px;
    width: 30vw;
  }
  .contact-container .contact-form form .form-submit {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    background-color: #ffcc00;
    border: none;
    border-radius: 1px;
    padding: 18px 42px;
    cursor: pointer;
    background-color: #000000;
    color: #ffffff;
  }
  .contact-container .contact-form form .form-submit:hover {
    font-size: 14px;
    font-weight: 600;
    color: #F5F6FC;
    background-color: transparent;
    border: 2px solid #ffcc00;
    border-radius: 1px;
    padding: 16px 40px;
    border-color: #000000;
    color: #7c7c7c;
  }
}/*# sourceMappingURL=contact.css.map */