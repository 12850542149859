@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Spectral+SC:wght@200&family=Spectral:wght@800&family=Teko:wght@300..700&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

@import '../../styles/globals';


.project-container {
    @include font-styling;

    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background-color: #000000;
    
    .project-title {
        padding: 16px 0;
        h1 {
            color: #ffffff;
            text-transform: uppercase;
        }
    }
    
    .project-data{
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 20px;

        .project-projectbg{
            
            img{
                height: 453px;
                width: 300px;
                object-fit: cover;
            }

        }

        .project-text{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            // background: #000000;
            color: white;
            padding: 32px;

            .project-logo{
                img {
                    height: 50px;
                    display: block;
                    margin: 0 auto;
                }
            }

            .projects-empty-space{
                padding-top: 10px;
            }
            
            .project-type, .project-name{
                font-size: 12px;
                font-weight: 600;
                
            }
            
            .project-name{
                font-size: 17px;
            }
        }
    }
    
    .projects-empty-space-2{
        padding-top: 28px;
    }
    .project-button{
        padding: 32px 0;
        
        button {
            @include button-styling;
        }

        button:hover {
            @include button-styling-hover;
        }
    }
}

@media (min-width: 768px) {
    .project-container {
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
        // width: 100%;
        // min-height: 100vh;
        
        .project-title {
            // position: absolute;
            // left: 0;
            

            h1 {
                color: #ffffff;
            }
    
        }
        .project-data-container{
            display: flex;
            flex-direction: row;
        }
    
        .project-data{
            flex-direction: row;
            width: calc(33.333% - 10px);
            margin: 5px;

            .project-projectbg, .project-text{
                position: relative;
                width: 100%;
            }

            .project-projectbg {
                img{
                    max-height: 350px;
                    max-width: 320px;
                    height: 350px;
                    width: 250px;
                }
            
            }
            
            .project-text{
                position: absolute;
                bottom: 0;
                left: 0;
                text-align: center;
                padding: 32px;
                
            }
        }

    }
}