* {
  font-family: "Exo 2", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

body {
  /* background-color: black; */
  /* color: #ffffff; */
  min-height: 100vh;
  font-size: 16px;
}