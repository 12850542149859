@import url('https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap');
@import '../../styles/globals';


.shop-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    
    @include font-styling;

    .shop-header {
        margin: 20vh 5vw;
        display: flex;
        flex-direction: column;
        align-items: left;
        // justify-items: center;
        text-align: left;

        .shop-title {
            h1 {
                @include font-styling;
                color: #000000;
            }
        }

        .shop-empty-space {
            padding-top: 20px;
        }

        .shop-desc {
            p {
                color: #7E7E7E;
            }
        }
    }

    .shop-textbox {
        margin: 0 5vw 15vh 5vw;

        input{
            @include input-box-style;
            border-right: none;
        }
        
        button{
            @include button-styling;
            background-color: #000000;
            color: #ffffff;
            border-left: none;
            
        }
        button:hover{
            @include button-styling-hover;
            border-color: #000000;
            color: #7c7c7c;
            border-left: none;
        }
    }
}


@media (min-width: 768px) {
    .shop-container {
        height: 100vh;

        .shop-header {
            margin: 20vh 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-items: center;
            text-align: center;

            .shop-title {
                h1 {
                    @include font-styling;
                    color: #000000;
                }
            }

            .shop-desc {
                width: 50%;

                p {
                    color: #7E7E7E;
                }
            }
        }
    }
}