@import url('https://fonts.googleapis.com/css2?family=Spectral+SC:wght@200&family=Spectral:wght@800&display=swap');
@import '../../styles/globals';


.contact-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;

    .contact-header {
        margin: 20vh 5vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;

        .contact-title {
            h1 {
                @include font-styling;
                color: #000000;
            }
        }

        .contact-empty-space {
            padding-top: 20px;
        }

        .contact-desc {
            p {
                color: #7E7E7E;
            }
        }
    }

    .contact-form {
        
        form {
            display: flex;
            flex-direction: column;
            gap: 15px;
            text-align: left;
            padding: 0 32px 32px 32px;;

            .contact-empty-space {
                padding-top: 20px;
            }

            .form-input,
            .form-select,
            .form-textarea {
                @include input-box-style;
            }

            .form-submit {
                @include button-styling;
                background-color: #000000;
                color: #ffffff;
            }

            .form-submit:hover {
                @include button-styling-hover;
                border-color: #000000;
                color: #7c7c7c;
            }

        }

    }
}

@media (min-width: 768px) {
    .contact-container {
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        .contact-header {
            margin: 20vh 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-items: center;
            text-align: center;
        }


        .contact-form {
            padding-top: 21vh;
            form {
                text-align: left;
                padding: 0 0;



                .form-input,
                .form-select,
                .form-textarea {
                    @include input-box-style;
                    width: 30vw;
                }

                .form-submit {
                    @include button-styling;
                    background-color: #000000;
                    color: #ffffff;
                }

                .form-submit:hover {
                    @include button-styling-hover;
                    border-color: #000000;
                    color: #7c7c7c;
                }

            }

        }
    }
}