@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Spectral+SC:wght@200&family=Spectral:wght@800&family=Teko:wght@300..700&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");
footer {
  margin: 0;
  overflow-x: hidden;
}

.footer {
  background: #000000;
  padding: 30px 0px;
  font-family: "Exo 2", sans-serif;
  text-align: center;
}
.footer .footer-row {
  width: 100%;
  margin: 1% 0%;
  padding: 0.6% 0%;
  color: gray;
  font-size: 0.8em;
}
.footer .footer-row a {
  text-decoration: none;
  color: gray;
  transition: 0.5s;
}
.footer .footer-row a:hover {
  color: #fff;
}
.footer .footer-row ul {
  width: 100%;
}
.footer .footer-row ul li {
  display: inline-block;
  margin: 0px 30px;
}
.footer .footer-row a i {
  font-size: 2em;
  margin: 0% 1%;
}

@media (max-width: 720px) {
  .footer {
    text-align: center;
    padding: 5%;
  }
  .footer .footer-row {
    margin-top: 20px;
  }
  .footer .footer-row ul li {
    display: unset;
    margin: 10px 10px;
    text-align: center;
  }
  .footer .footer-row a i {
    margin: 0% 3%;
  }
}/*# sourceMappingURL=footer.css.map */