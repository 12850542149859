@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Spectral+SC:wght@200&family=Spectral:wght@800&family=Teko:wght@300..700&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

@import '../../../../styles/globals';

.proj-software-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;


    .proj-software-title {
        margin: 20vh 5vw;
        display: flex;
        flex-direction: column;
        align-items: left;
        text-align: left;

        .proj-software-empty-space {
            padding-top: 20px;

        }

        .proj-software-header {
            h1 {
                @include font-styling;
                color: #000000;
            }

        }

        .proj-software-desc {

            p {
                color: #7E7E7E;
            }

        }

    }

    .proj-software-contents {
        display: grid;
        grid-template-columns: 1fr;
        gap: 15px;
        margin: 0 5vw 15vh 5vw;

        .proj-software-data {
            img {
                max-width: 80vw;
                max-height: 60vh;
                object-fit: cover;
                cursor: pointer;
            }
        }

        .proj-software-video {
            video {
                width: 100%;
                height: auto;
            }
        }

        .proj-software-apps {
            cursor: pointer;


            .iframe-wrapper {
                position: relative;
                width: 100%;
                height: 300px;
                overflow: hidden;
                border: none;
                margin-top: 10px;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 300px;
                    object-fit: contain;
                }
            }

            .iframe-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(0, 0, 0, 0.5);
                color: white;
                font-size: 1.2rem;
                opacity: 0;
                transition: opacity 0.3s;
            }

            .iframe-wrapper:hover .iframe-overlay {
                opacity: 1;
            }
        }
    }
}


@media (min-width: 768px) {
    .proj-software-container {
        display: flex;
        flex-direction: column;
        height: auto;


        .proj-software-title {
            margin: 20vh 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-items: center;
            text-align: center;

            .proj-software-empty-space {
                padding-top: 20px;

            }

            .proj-software-header {
                h1 {
                    @include font-styling;
                    color: #000000;
                }

            }

            .proj-software-desc {
                width: 50%;

                p {
                    color: #7E7E7E;
                }

            }

        }

        .proj-software-contents {
            grid-template-columns: repeat(3, 1fr);
            row-gap: 15vh;
            column-gap: -50px;

            .proj-software-data {
                img {
                    min-width: unset;
                    max-height: unset;
                    height: auto;
                    width: 21vw;
                    object-fit: cover;
                }
            }

            .proj-software-video {
                video {
                    width: 21vw;
                    height: auto;
                }
            }

            // .proj-software-apps {

            //     img {
            //         width: 0px;
            //         height: 0px;
            //     }
            // }
        }
    }

}