@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Spectral+SC:wght@200&family=Spectral:wght@800&family=Teko:wght@300..700&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");
.proj-atlantis-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: auto;
}
.proj-atlantis-container .proj-atlantis-title {
  margin: 20vh 5vw;
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
}
.proj-atlantis-container .proj-atlantis-title .proj-atlantis-empty-space {
  padding-top: 20px;
}
.proj-atlantis-container .proj-atlantis-title .proj-atlantis-header h1 {
  font-family: "Exo 2", sans-serif;
  color: #000000;
}
.proj-atlantis-container .proj-atlantis-title .proj-atlantis-desc p {
  color: #7E7E7E;
}
.proj-atlantis-container .proj-atlantis-contents {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  margin: 0 5vw 15vh 5vw;
}
.proj-atlantis-container .proj-atlantis-contents .proj-atlantis-img img {
  max-width: 80vw;
  max-height: 60vh;
  -o-object-fit: cover;
     object-fit: cover;
  cursor: pointer;
}
.proj-atlantis-container .proj-atlantis-contents .proj-atlantis-video video {
  width: 100%;
  height: auto;
}
.proj-atlantis-container .proj-atlantis-contents .proj-atlantis-apps {
  cursor: pointer;
}
.proj-atlantis-container .proj-atlantis-contents .proj-atlantis-apps .iframe-wrapper {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  border: none;
  margin-top: 10px;
  cursor: pointer;
}
.proj-atlantis-container .proj-atlantis-contents .proj-atlantis-apps .iframe-wrapper img {
  width: 100%;
  height: 300px;
  -o-object-fit: contain;
     object-fit: contain;
}
.proj-atlantis-container .proj-atlantis-contents .proj-atlantis-apps .iframe-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 1.2rem;
  opacity: 0;
  transition: opacity 0.3s;
}
.proj-atlantis-container .proj-atlantis-contents .proj-atlantis-apps .iframe-wrapper:hover .iframe-overlay {
  opacity: 1;
}

@media (min-width: 768px) {
  .proj-atlantis-container {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .proj-atlantis-container .proj-atlantis-title {
    margin: 20vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    text-align: center;
  }
  .proj-atlantis-container .proj-atlantis-title .proj-atlantis-empty-space {
    padding-top: 20px;
  }
  .proj-atlantis-container .proj-atlantis-title .proj-atlantis-header h1 {
    font-family: "Exo 2", sans-serif;
    color: #000000;
  }
  .proj-atlantis-container .proj-atlantis-title .proj-atlantis-desc {
    width: 50%;
  }
  .proj-atlantis-container .proj-atlantis-title .proj-atlantis-desc p {
    color: #7E7E7E;
  }
  .proj-atlantis-container .proj-atlantis-contents {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 15vh;
    -moz-column-gap: -50px;
         column-gap: -50px;
  }
  .proj-atlantis-container .proj-atlantis-contents .proj-atlantis-img img {
    min-width: unset;
    max-height: unset;
    height: auto;
    width: 21vw;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .proj-atlantis-container .proj-atlantis-contents .proj-atlantis-video video {
    width: 21vw;
    height: auto;
  }
  .proj-atlantis-container .proj-atlantis-contents .proj-atlantis-apps iframe {
    width: 21vw;
    height: 300px;
  }
}/*# sourceMappingURL=atlantis.css.map */