@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Spectral+SC:wght@200&family=Spectral:wght@800&family=Teko:wght@300..700&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");
.portfolio-data-container {
  display: flex;
  flex-direction: column;
  background-color: #000000;
}
.portfolio-data-container .portfolio-data {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
}
.portfolio-data-container .portfolio-data .portfolio-portfoliobg {
  cursor: pointer;
}
.portfolio-data-container .portfolio-data .portfolio-portfoliobg img {
  height: 100vh;
  width: 100vw;
  -o-object-fit: cover;
     object-fit: cover;
}
.portfolio-data-container .portfolio-data .portfolio-text {
  position: absolute;
  bottom: 5%;
  left: 0;
  width: 100%;
  color: white;
  padding: 32px;
}
.portfolio-data-container .portfolio-data .portfolio-text .portfolio-logo img {
  height: 50px;
  display: block;
  margin: 0 auto;
}
.portfolio-data-container .portfolio-data .portfolio-text .portfolio-empty-space {
  padding-top: 10px;
}
.portfolio-data-container .portfolio-data .portfolio-text .portfolio-type,
.portfolio-data-container .portfolio-data .portfolio-text .portfolio-name {
  font-size: 12px;
  font-weight: 600;
}
.portfolio-data-container .portfolio-data .portfolio-text .portfolio-name {
  font-size: 17px;
}
.portfolio-data-container .prev,
.portfolio-data-container .next {
  display: none;
}

@media (min-width: 768px) {
  .portfolio-data-container {
    flex-direction: row;
    overflow-x: hidden;
  }
  .portfolio-data-container .portfolio-data-mb {
    display: none;
  }
  .portfolio-data-container .portfolio-data {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 20px;
  }
  .portfolio-data-container .portfolio-data .portfolio-portfoliobg {
    cursor: pointer;
  }
  .portfolio-data-container .portfolio-data .portfolio-portfoliobg img {
    height: 100vh;
    width: 30vw;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .portfolio-data-container .portfolio-data .portfolio-portfoliobg img:hover {
    scale: 1.1;
  }
  .portfolio-data-container .portfolio-data .portfolio-text {
    position: absolute;
    bottom: 5%;
    left: 0;
    width: 100%;
    color: white;
    padding: 32px;
  }
  .portfolio-data-container .portfolio-data .portfolio-text .portfolio-logo img {
    height: 50px;
    display: block;
    margin: 0 auto;
  }
  .portfolio-data-container .portfolio-data .portfolio-text .portfolios-empty-space {
    padding-top: 10px;
  }
  .portfolio-data-container .portfolio-data .portfolio-text .portfolio-type,
  .portfolio-data-container .portfolio-data .portfolio-text .portfolio-name {
    font-size: 12px;
    font-weight: 600;
  }
  .portfolio-data-container .portfolio-data .portfolio-text .portfolio-name {
    font-size: 17px;
  }
  .portfolio-data-container .prev,
  .portfolio-data-container .next {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    border-radius: 0 3px 3px 0;
    border: none;
    background-color: unset;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    z-index: 100;
  }
  .portfolio-data-container .prev {
    left: 0;
    border-radius: 3px 0 0 3px;
  }
  .portfolio-data-container .next {
    right: 0;
    border-radius: 0 3px 3px 0;
  }
  .portfolio-data-container .prev img, .portfolio-data-container .next img {
    width: 60px;
    height: 60px;
  }
}/*# sourceMappingURL=portfolio.css.map */