@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Spectral+SC:wght@200&family=Spectral:wght@800&family=Teko:wght@300..700&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");
.navbar-container {
  background: transparent;
  height: 0px;
}
.navbar-container .navbar-social {
  display: none;
}
.navbar-container .navbar-social.black {
  color: black;
}
.navbar-container .navbar-social.black a {
  color: black;
}
.navbar-container .navbar-social.black a:hover {
  color: #727272;
}
.navbar-container .navbar-logo {
  display: none;
}
.navbar-container .navbar-menus {
  display: none;
}
.navbar-container .navbar-menus.black {
  color: black;
}
.navbar-container .navbar-menus.black p a {
  color: black;
}
.navbar-container .mobile-menu-container {
  font-family: "Exo 2", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  padding: 20px;
  z-index: 1000044;
}
.navbar-container .mobile-menu-container img {
  width: 30px;
}
.navbar-container .mobile-menu-container .mobile-menu-logo img {
  width: 30px;
}
.navbar-container .mobile-menu {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 60%;
  display: flex;
  flex-direction: column;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(255, 255, 255, 0.258);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  align-items: flex-end;
  justify-content: flex-start;
  padding: 20px 0 0 5px;
  z-index: 100004466000000000000;
}
.navbar-container .mobile-menu img {
  width: 30px;
  -o-object-fit: contain;
     object-fit: contain;
}
.navbar-container .mobile-menu p {
  margin: 5px 15px;
  padding: 0;
  transition: transform 0.3s ease;
  white-space: nowrap;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.navbar-container .mobile-menu p a {
  display: inline-block;
  position: relative;
  color: #818380;
  text-decoration: none;
  text-transform: none;
  font-size: 24px;
  padding: 0.625rem;
  transition: background-color 0.3s ease;
  border-radius: 3.125rem;
}
.navbar-container .mobile-menu p a:hover {
  color: #000000;
  font-weight: 700;
}

@media (min-width: 1119px) {
  .navbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: relative;
    top: 30px;
    background: transparent;
    z-index: 100000000000;
  }
  .navbar-container .navbar-social {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .navbar-container .navbar-social a {
    text-decoration: none;
    color: #ffffff;
    transition: 0.5s;
  }
  .navbar-container .navbar-social a:hover {
    color: white;
  }
  .navbar-container .navbar-social ul {
    width: 100%;
  }
  .navbar-container .navbar-social ul li {
    display: inline-block;
    margin: 0px 30px;
  }
  .navbar-container .navbar-social a i {
    font-size: 1.2em;
    margin: 0% 1%;
  }
  .navbar-container .navbar-logo {
    display: flex;
    position: relative;
    left: 50px;
  }
  .navbar-container .navbar-logo img {
    width: 30px;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .navbar-container .navbar-menus {
    font-family: "Exo 2", sans-serif;
    font-weight: 500;
    font-size: 12px;
    text-transform: none;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .navbar-container .navbar-menus p {
    transition: transform 0.3s ease;
    white-space: nowrap;
  }
  .navbar-container .navbar-menus p a {
    display: inline-block;
    position: relative;
    color: #F7F7F7;
    text-decoration: none;
    padding: 0.625rem;
    transition: background-color 0.3s ease;
  }
  .navbar-container .navbar-menus p.selected {
    color: #808080;
  }
  .navbar-container .mobile-menu-container {
    display: none;
  }
  .navbar-container .mobile-menu {
    display: none;
  }
}/*# sourceMappingURL=navbar.css.map */