@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Spectral+SC:wght@200&family=Spectral:wght@800&family=Teko:wght@300..700&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");
.project-container {
  font-family: "Exo 2", sans-serif;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: #000000;
}
.project-container .project-title {
  padding: 16px 0;
}
.project-container .project-title h1 {
  color: #ffffff;
  text-transform: uppercase;
}
.project-container .project-data {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
}
.project-container .project-data .project-projectbg img {
  height: 453px;
  width: 300px;
  -o-object-fit: cover;
     object-fit: cover;
}
.project-container .project-data .project-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: white;
  padding: 32px;
}
.project-container .project-data .project-text .project-logo img {
  height: 50px;
  display: block;
  margin: 0 auto;
}
.project-container .project-data .project-text .projects-empty-space {
  padding-top: 10px;
}
.project-container .project-data .project-text .project-type, .project-container .project-data .project-text .project-name {
  font-size: 12px;
  font-weight: 600;
}
.project-container .project-data .project-text .project-name {
  font-size: 17px;
}
.project-container .projects-empty-space-2 {
  padding-top: 28px;
}
.project-container .project-button {
  padding: 32px 0;
}
.project-container .project-button button {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  background-color: #ffcc00;
  border: none;
  border-radius: 1px;
  padding: 18px 42px;
  cursor: pointer;
}
.project-container .project-button button:hover {
  font-size: 14px;
  font-weight: 600;
  color: #F5F6FC;
  background-color: transparent;
  border: 2px solid #ffcc00;
  border-radius: 1px;
  padding: 16px 40px;
}

@media (min-width: 768px) {
  .project-container .project-title h1 {
    color: #ffffff;
  }
  .project-container .project-data-container {
    display: flex;
    flex-direction: row;
  }
  .project-container .project-data {
    flex-direction: row;
    width: calc(33.333% - 10px);
    margin: 5px;
  }
  .project-container .project-data .project-projectbg, .project-container .project-data .project-text {
    position: relative;
    width: 100%;
  }
  .project-container .project-data .project-projectbg img {
    max-height: 350px;
    max-width: 320px;
    height: 350px;
    width: 250px;
  }
  .project-container .project-data .project-text {
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    padding: 32px;
  }
}/*# sourceMappingURL=projects.css.map */